// Imports
import AxiosConfig from './LensAxiosConfig'
import firebase from '../config/FirebaseConfig'
import { createDateRangeQuery } from '../../utils/lens/filters/filterQueryUtils'
import axios from 'axios';

let seriesCancelToken = null;
// Fetch period data for groups
const getGroupTimeSeriesData = async (lensId, query, interval) => {
  const { Aa: token } = firebase.auth().currentUser

  if (seriesCancelToken){
    seriesCancelToken.cancel("New request incoming, cancelling previous one.")
  }
  const { group, sort, filters, currentWindow, selectedRowKeys, selectedColumns, startDate, endDate } = query

  const filtersExcludingDate = filters.filter(andFilters => andFilters?.[0]?.key !== 'event_date')
  const dateFilters = createDateRangeQuery({ start: startDate, end: endDate })
  const rowFilters = selectedRowKeys.map(value => ({
    key: "group_by",
    value: value ?? '',
    operation: "equals"
  }))

  const payload = {
    metadata: {
      group_by: group || 'ad_name',
      page: 1,
      limit: 100,
      interval,
      ...(sort && { sort }),
      ...(currentWindow && { attr_window: currentWindow })
    },
    query: [
      ...filtersExcludingDate,
      ...dateFilters,
      rowFilters
    ],
    "selected_columns": selectedColumns.map(key => ({ key }))
  }

  seriesCancelToken = axios.CancelToken.source()

  const { data } = await AxiosConfig.post(
    `/ads/groups/${lensId}/timeseries`,
    payload,
    {
      cancelToken: seriesCancelToken.token,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data.data // We have nested data properties in the response (idk bro)
}

export default {
  getGroupTimeSeriesData
}