<template>
  <div class="w-full flex flex-col p-6">
    <!-- Coming Soon Banner -->
    <div
      class="flex flex-col mx-auto w-full rounded-xl"
      style="max-width: 900px; height: 157px; background: rgba(6, 7, 16, 0.92);"
    >
      <div class="flex h-full ">
        <!-- Left Column -->
        <div class="flex flex-col justify-between flex-1  px-5 py-4">
          <!-- Top Text -->
          <BaseText
            type="body"
            size="sm"
            class="text-white text-opacity-70"
          >
            Coming Soon
          </BaseText>
          <!-- Bottom Text -->
          <div class="flex flex-col gap-1">
            <div class="flex items-start gap-1">
              <img
                src="../../assets/icons/DNA.svg"
                alt="Creative DNA"
                class="w-5 h-5"
              >
              <BaseText
                type="label"
                size="sm"
                class="text-white"
              >
                Custom Creative DNA
              </BaseText>
            </div>
            <BaseText
              type="body"
              size="sm"
              class="text-white text-opacity-70"
              style="max-width: 312px;"
            >
              Automatically detect and group ads based on naming conventions.
            </BaseText>
          </div>
        </div>

        <!-- Right Column -->
        <div class="flex flex-col justify-between">
          <!-- This will be an image -->
          <img
            src="../../assets/images/creativeDNA.svg"
            alt="Lens Settings Coming Soon"
            class="w-full h-full object-cover"
          >
        </div>
      </div>
    </div>

    <!-- Avatar Upload Section -->
    <div
      class="flex mx-auto w-full mt-7"
      style="max-width: 900px"
    >
      <div
        class="flex flex-col gap-1 mb-6 xl:mb-0 w-2/3"
        style="padding-right: 104px"
      >
        <BaseText
          type="label"
          size="md"
          class="text-text-muted"
        >
          General
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal"
        >
          Manage the superficial details of this Lens brand.
        </BaseText>
      </div>

      <div class="w-full">
        <!-- Company Name -->
        <BaseInputV2
          :value="lens.name"
          label="Company Name"
          placeholder="Enter your company name"
          class="mb-2.5"
          :error="showErrors && !form.companyName ? 'Company name is required' : ''"
          required
          @input="updateFormField('companyName', $event)"
        />

        <div class="flex gap-5 bg-background-normal rounded-md p-1.5 flex-1">
          <img
            v-if="avatarPreview"
            :src="avatarPreview"
            alt="Preview"
            class="w-11 h-11 rounded-md"
          >
          <div
            v-else-if="!lens.avatar"
            class="w-11 h-11 flex items-center justify-center rounded-md bg-neutral-400"
          >
            <span
              class="text-background-hover text-label-md font-medium"
            >
              {{ getInitials(lens.name) }}
            </span>
          </div>
          <img
            v-else
            :src="lens.avatar"
            alt="Avatar"
            class="w-11 h-11 rounded-md"
          >
          <div class="flex flex-col gap-2">
            <div class="flex items-center gap-2 py-1.5">
              <button
                class="standard-btn flex items-center gap-1.5 rounded-md pl-1.5 py-1.5 pr-2.5"
                @click="handleFileInput"
              >
                <input
                  ref="fileInput"
                  class="hidden"
                  type="file"
                  @change="handleFileUpload"
                >
                <UploadIcon class="text-icon-normal" />
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  Upload
                </BaseText>
              </button>
              <button
                class="px-2.5 py-1.5 rounded-md transition-colors"
                :class="hasAvatarToRemove ? 'hover:bg-neutral-25 text-text-muted' : 'cursor-default bg-neutral-10 text-text-disabled'"
                @click="removeAvatar"
              >
                <BaseText
                  type="label"
                  size="sm"
                >
                  Remove
                </BaseText>
              </button>
            </div>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal"
            >
              We support PNGs and JPEGs.
            </BaseText>
          </div>
        </div>
      </div>
    </div>

    <!-- Divider -->
    <div class="section-grid mx-auto py-7">
      <div class="w-full col-span-2 h-px bg-neutral-50" />
    </div>

    <!-- Benchmarking Section -->
    <div
      class="flex flex-col mx-auto w-full"
      style="max-width: 900px"
    >
      <!-- Header/Title -->
      <div class="flex flex-col gap-1">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted"
        >
          Benchmarks
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal"
          style="max-width: 550px"
        >
          Adjust your default segment for performance benchmarking to ensure you are comparing yourself to similar companies.
        </BaseText>

        <!-- Side by side dropdowns -->
        <div class="rounded-xl flex mt-2 gap-5 z-50">
          <!-- Industry Dropdown -->
          <BaseSingleDropdown
            show-label-space
            label="Industry / Niche"
            :options="industryOptions"
            class="flex-1"
            option-key="label"
            search-key="label"
            enable-search
            search-placeholder="Search Industries"
            :selected-obj="benchmarkSettings.selectedIndustry || getIndustryObj(lens.niche)"
            use-parent-width
            @change="updateBenchmarkField('selectedIndustry', $event)"
          />

          <!-- AOV Dropdown -->
          <BaseSingleDropdown
            show-label-space
            label="Average Order Value (AOV)"
            :options="aovOptions"
            class="flex-1"
            option-key="label"
            :selected-obj="benchmarkSettings.selectedAOV || getAOVObj(lens.aov_min, lens.aov_max)"
            use-parent-width
            @change="updateBenchmarkField('selectedAOV', $event)"
          />

          <!-- GMV/Revenue Dropdown -->
          <BaseSingleDropdown
            show-label-space
            label="GMV/Revenue"
            :options="revenueOptions"
            class="flex-1"
            option-key="label"
            :selected-obj="benchmarkSettings.selectedRevenue || getRevenueObj(lens.gmv)"
            use-parent-width
            @change="updateBenchmarkField('selectedRevenue', $event)"
          />
        </div>
      </div>
      <!-- divider -->
      <div class="w-full h-px my-5 bg-neutral-50" />

      <!-- Ad Account Section -->
      <div
        class="flex flex-col mx-auto w-full"
        style="max-width: 900px"
      >
        <!-- Header/Title -->
        <div class="flex flex-col gap-1">
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Ad Account Settings
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal"
            style="max-width: 550px"
          >
            Adjust your default segment for performance benchmarking to ensure you are comparing yourself to similar companies.
          </BaseText>
        </div>

        <!-- Ad Account Settings -->
        <div class="flex flex-col gap-5 mt-2">
          <!-- Side by side dropdowns - first row -->
          <div class="rounded-xl flex gap-5">
            <!-- Currency -->
            <BaseSingleDropdown
              show-label-space
              label="Currency"
              :options="currencies"
              disabled
              class="z-30 flex-1"
              option-key="label"
              search-key="label"
              :selected-obj="adAccountSettings.currency || getCurrencyObj(lens.currency)"
              enable-search
              search-placeholder="Search Currencies"
              use-parent-width
              @change="updateAdAccountField('currency', $event)"
            />

            <!-- Reporting Timezone -->
            <BaseSingleDropdown
              show-label-space
              label="Reporting Timezone"
              option-key="label"
              class="z-20 flex-1"
              search-key="label"
              disabled
              enable-search
              search-placeholder="Search Timezones"
              :options="timeZones"
              :selected-obj="adAccountSettings.timezone || getTimezoneObj(lens.timezone)"
              use-parent-width
              @change="updateAdAccountField('timezone', $event)"
            />

            <!-- Goal Metric -->
            <div class="flex-1 flex-col">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted mb-1"
              >
                Goal Metric
              </BaseText>
              <!-- Dropdown Opener -->
              <div
                v-on-clickaway="() => showGoalMetricDropdown = false"
                class="relative"
              >
                <button
                  class="flex items-center gap-1 w-full px-2 py-1.5 bg-white flex-nowrap whitespace-nowrap rounded-lg focus:outline-none shadow-sm border border-border-normal"
                  @click="showGoalMetricDropdown = true"
                >
                  <BaseText
                    type="body"
                    size="sm"
                    class="flex-grow text-left truncate mr-0.5"
                    :class="adAccountSettings.goalMetric ? 'text-text-muted' : 'text-text-normal'"
                  >
                    {{ adAccountSettings.goalMetric ? getMetricLookup?.[adAccountSettings.goalMetric]?.name : (lens.goal_metric ? getMetricLookup?.[lens.goal_metric]?.name : 'Select Metric') }}
                  </BaseText>
                  <ChevronIcon class="text-icon-normal" />
                </button>
                <div
                  v-if="showGoalMetricDropdown"
                  class="absolute top-full left-0 right-0 z-50 mt-1"
                >
                  <PropertySelectDropdown
                    search-placeholder="Search Metrics"
                    metrics-only
                    emit-keys-only
                    use-parent-width
                    :selected-metrics="adAccountSettings.goalMetric ? [adAccountSettings.goalMetric] : (lens.goal_metric ? [lens.goal_metric] : [])"
                    @optionSelected="handleSelectGoalMetric"
                    @close="showGoalMetricDropdown = false"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Attribution Window - second row -->
          <div>
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted mb-1"
            >
              Default Attribution Window
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal w-full"
              style="max-width: 550px"
            >
              The time frame in which a user action can be credited for a conversion. Helps track the effectiveness of your campaigns.
            </BaseText>
            <div class="flex items-center gap-5 mt-2">
              <BaseSingleDropdown
                :options="clickAttributionWindows"
                class="z-10 flex-1"
                option-key="label"
                :selected-obj="adAccountSettings.attributionWindowClickType || getAttributionWindowObj(lens.click_attribution_window, clickAttributionWindows)"
                placeholder="Click"
                use-parent-width
                @change="updateAdAccountField('attributionWindowClickType', $event)"
              />
              <BaseSingleDropdown
                :options="viewAttributionWindows"
                class="z-10 flex-1"
                option-key="label"
                :selected-obj="adAccountSettings.attributionWindowViewType || getAttributionWindowObj(lens.view_attribution_window, viewAttributionWindows)"
                placeholder="View"
                use-parent-width
                @change="updateAdAccountField('attributionWindowViewType', $event)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- divider -->
      <div class="w-full h-px my-5 bg-neutral-50" />

      <!-- Data Sources Section -->
      <div
        class="flex flex-col mx-auto w-full"
        style="max-width: 900px"
      >
        <!-- Header/Title -->
        <div class="flex flex-col gap-1">
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Data Sources
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal"
          >
            Connect your ad accounts and data enrichment platforms.
          </BaseText>
        </div>

        <!-- Data Sources Table -->
        <div
          class="flex flex-col justify-center gap-2 p-3"
          style="border-radius: 10px;"
        >
          <!-- Data Sources Table -->
          <div class="flex flex-col gap-4 mt-4">
            <!-- Meta Data Source -->
            <div class="flex items-center justify-between border border-border-normal rounded-xl py-2 px-3">
              <div class="flex items-center gap-3">
                <!-- Platform Icon -->
                <div class="w-6 h-6 flex items-center justify-center rounded-md">
                  <img
                    src="../../assets/images/meta.svg"
                    alt="Meta"
                    class="w-4 h-4"
                  >
                </div>

                <!-- Platform Name -->
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  Meta Ads
                </BaseText>

                <!-- Ad Account Name and ID -->
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-normal"
                >
                  {{ lens.ad_accounts[0].account_name }} - {{ lens.ad_accounts[0].account_id.replace('act_', '') }}
                </BaseText>
              </div>

              <!-- Coming Soon Label -->
              <div class="flex items-center gap-2">
                <div class="flex items-center py-1 px-1.5 gap-1 bg-primary-green-10 rounded-md select-none">
                  <div class="w-1.5 h-1.5 flex bg-primary-green-100 rounded-full" />
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-secondary-green-300"
                  >
                    Connected
                  </BaseText>
                </div>

                <BaseButton
                  text
                  :loading="loading"
                  @click="handleConnectMeta"
                >
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    Edit Connection
                  </BaseText>
                </BaseButton>
              </div>
            </div>

            <!-- Coming Soon Data Sources -->
            <div
              v-for="(source, index) in comingSoonDataSources"
              :key="index"
              class="flex items-center justify-between border border-border-normal rounded-xl py-2 px-3"
            >
              <div class="flex items-center gap-3">
                <!-- Platform Icon -->
                <div class="w-6 h-6 flex items-center justify-center rounded-md">
                  <img
                    v-if="source.value === 'tiktok_ads'"
                    src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/da1bf604-03c8-4d61-d5ce-a3009b4ecb00/public"
                    alt="TikTok"
                    class="w-4 h-4"
                  >
                  <img
                    v-else-if="source.value === 'google_ads'"
                    src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/67087404-2693-4a52-54ab-ef8e051d5d00/public"
                    alt="Google Ads"
                    class="w-4 h-4"
                  >
                  <img
                    v-else-if="source.value === 'linkedin_ads'"
                    src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/9a6a8bcc-b12b-4305-b938-c0f43cf20400/public"
                    alt="LinkedIn"
                    class="w-4 h-4"
                  >
                  <img
                    v-else-if="source.value === 'snapchat_ads'"
                    src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/ac179971-db15-415e-7515-715c7ed5e900/public"
                    alt="Snapchat"
                    class="w-4 h-4"
                  >
                  <img
                    v-else-if="source.value === 'x_ads'"
                    src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/2dea0bc8-6e57-4b26-8abc-a2d64008e300/public"
                    alt="X"
                    class="w-4 h-4"
                  >
                  <img
                    v-else-if="source.value === 'pinterest_ads'"
                    src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/4a256268-93b2-479e-27eb-25683d8b1500/public"
                    alt="Pinterest"
                    class="w-4 h-4"
                  >
                  <img
                    v-else-if="source.value === 'app_lovin_ads'"
                    src="https://imagedelivery.net/90JjdkKV9bTv9MzTQOLrAg/e4024ac6-032d-4307-1084-f887112d1800/public"
                    alt="App Lovin"
                    class="w-4 h-4"
                  >
                </div>

                <!-- Platform Name -->
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ source.label }}
                </BaseText>
              </div>

              <!-- Coming Soon Label -->
              <div class="flex items-center gap-2">
                <div class="flex items-center py-1 px-1.5 gap-1 bg-background-normal rounded-md select-none">
                  <div class="w-1.5 h-1.5 bg-text-subdued rounded-full" />
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-muted"
                  >
                    {{ source.comingSoon }}
                  </BaseText>
                </div>

                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfdLeqezwXe-5mpFzbOOkZ7AupFHu6om_piRDBdW7_z4am8PA/viewform"
                  target="_blank"
                  class="text-text-muted"
                >
                  <BaseButton text>
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-text-muted"
                    >
                      Request Access
                    </BaseText>
                  </BaseButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- divider -->
      <div class="w-full h-px my-5 bg-neutral-50" />

      <!-- Delete Lens Section -->
      <div
        class="flex flex-col mx-auto w-full"
        style="max-width: 900px"
      >
        <!-- Header/Title -->
        <div class="flex flex-col gap-1">
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Delete Lens Brand
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal"
          >
            This will permanently delete all associated data sources and saved data.
          </BaseText>
        </div>

        <div class="mt-4">
          <BaseButton
            destroy
            @click="openConfirmDeleteLensModal"
          >
            <BaseText
              type="label"
              size="sm"
              class="text-text-danger"
            >
              Delete Lens
            </BaseText>
          </BaseButton>
        </div>
      </div>

      <!-- divider -->
      <div class="w-full h-px my-5 bg-neutral-50" />
    </div>
    <!-- Add this portal at the end of your template, before the closing </div> tag -->
    <ConfirmDeleteModal
      v-if="showConfirmDeleteLensModal"
      show-checkbox
      lens
      @confirm="handleDeleteLens"
      @close="showConfirmDeleteLensModal = false"
    />
  </div>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import { nanoid } from 'nanoid'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
// Icons
import DefaultAvatarIcon from '../globals/Icons/DefaultAvatarIcon.vue'
import UploadIcon from '../globals/Icons/UploadIcon.vue'
import ResetPasswordIcon from '../globals/Icons/SettingsIcons/ResetPasswordIcon.vue'
import EmailSentIcon from '../globals/Icons/EmailSentIcon.vue'
import AltAlertIcon from '../globals/Icons/AltAlertIcon.vue'
import AnonBenchmarksIcon from '../globals/Icons/LensIcons/AnonBenchmarksIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'
import PropertySelectDropdown from '../lens/filters/PropertySelectDropdown.vue'
import LensAPI from '@/api/lens'
import { mixin as clickaway } from 'vue-clickaway2'
// Import timezone and currency data
import timeZonesData from '../lens/lensCreationModal/dropdownData/IANATimeZones.json'
import currenciesData from '../lens/lensCreationModal/dropdownData/ISOCurrecies.json'
import ConfirmDeleteModal from '../globals/ConfirmDeleteModal.vue'

export default {
  name: 'LensSettings',
  components: {
    DefaultAvatarIcon,
    UploadIcon,
    AnonBenchmarksIcon,
    ChevronIcon,
    PropertySelectDropdown,
    ConfirmDeleteModal
  },
  mixins: [clickaway],
  data () {
    return {
      currentUser: {},
      updatedUser: {},
      avatarPreview: '',
      avatarUpload: null,
      submitAttempted: false,
      loadingSubmit: false,
      loadingPasswordReset: false,
      passwordResetEmailSent: false,
      showGoalMetricDropdown: false,
      selectedAdAccount: null,
      url: null,
      twAccountId: null,
      authWindow: null,
      loading: false,
      adAccountSettings: {
        goalMetric: '',
        attributionWindowClickType: null,
        attributionWindowViewType: null,
        currency: null,
        timezone: null
      },
      clickAttributionWindows: [
        { label: '1 Day Click', value: '1d' },
        { label: '7 Days Click', value: '7d' },
        { label: '28 Days Click', value: '28d' }
      ],
      viewAttributionWindows: [
        { label: '1 Day View', value: '1d' },
        { label: '7 Days View', value: '7d' },
        { label: '28 Days View', value: '28d' }
      ],
      benchmarkSettings: {
        selectedIndustry: null,
        selectedAOV: null,
        selectedRevenue: null
      },
      industryOptions: [
        { label: 'Art', value: 'art' },
        { label: 'Baby', value: 'baby' },
        { label: 'Books', value: 'books' },
        { label: 'Clothing', value: 'clothing' },
        { label: 'Electronics', value: 'electronics' },
        { label: 'Fashion Accessories', value: 'fashion_accessories' },
        { label: 'Food & Beverages', value: 'food_beverages' },
        { label: 'Health & Beauty', value: 'health_beauty' },
        { label: 'Home & Garden', value: 'home_garden' },
        { label: 'Pet Supplies', value: 'pet_supplies' },
        { label: 'Sporting Goods', value: 'sporting_goods' },
        { label: 'Toys & Hobbies', value: 'toys_hobbies' },
        { label: 'Other', value: 'other' }
      ],
      aovOptions: [
        { label: '<$100', value: '<$100' },
        { label: '$100+', value: '$100+' }
      ],
      revenueOptions: [
        { label: '<$1M', value: '<$1M' },
        { label: '$1M-$10M', value: '$1M-$10M' },
        { label: '$10M+', value: '$10M+' }
      ],
      comingSoonDataSources: [
        { label: 'TikTok Ads', value: 'tiktok_ads', comingSoon: 'Coming Q2 \'25' },
        { label: 'Google / YouTube Ads', value: 'google_ads', comingSoon: 'Coming Q2 \'25' },
        { label: 'LinkedIn Ads', value: 'linkedin_ads', comingSoon: 'Coming Q2 \'25' },
        { label: 'Snapchat Ads', value: 'snapchat_ads', comingSoon: 'Coming Q2 \'25' },
        { label: 'X Ads', value: 'x_ads', comingSoon: 'Coming Q2 \'25' },
        { label: 'Pinterest Ads', value: 'pinterest_ads', comingSoon: 'Coming Q3 \'25' },
        { label: 'App Lovin Ads', value: 'app_lovin_ads', comingSoon: 'Coming Q3 \'25' }
      ],
      currencies: [],
      timeZones: [],
      form: {
        companyName: ''
      },
      showErrors: false,
      avatarWasRemoved: false,
      hasUnsavedChanges: false,
      showConfirmDeleteLensModal: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    ...mapGetters('RouterModule', ['getCurrentRouteId']),
    ...mapGetters('LensModule', ['getLenses']),
    hasAvatarToRemove () {
      return this.lens.avatar || this.avatarPreview
    },
    lens () {
      return this.getLenses.find(lens => lens.id == this.getCurrentRouteId)
    }
  },
  watch: {
    // Add a watcher for the lens object
    lens: {
      handler (newLens, oldLens) {
        if (newLens && (!oldLens || newLens.id !== oldLens.id)) {
          // Re-initialize form data when lens changes
          this.initializeFromLensData()
        }
      },
      deep: true
    }
  },
  created () {
    // Register the save method with the parent component using the specific event name
    console.log('LensSettings component created, registering event listener')
    this.$root.$on('lens-settings-save-clicked', this.handleFormSubmit)
  },
  beforeDestroy () {
    // Clean up the event listener
    console.log('LensSettings component being destroyed, removing event listener')
    this.$root.$off('lens-settings-save-clicked', this.handleFormSubmit)
  },
  mounted () {
    console.log('LensSettings component mounted')
    // Set current Lens here
    // Initialize timezone and currency data
    this.initializeDropdownData()

    // Initialize the form with lens data if available
    this.initializeFromLensData()
  },
  methods: {
    initializeFromLensData () {
      if (!this.lens) return

      // Initialize form data from lens
      this.form = {
        companyName: this.lens.name || ''
      }

      // Initialize ad account settings from lens data
      this.adAccountSettings = {
        goalMetric: this.lens.goal_metric || '',
        attributionWindowClickType: this.getAttributionWindowObj(this.lens.click_attribution_window, this.clickAttributionWindows),
        attributionWindowViewType: this.getAttributionWindowObj(this.lens.view_attribution_window, this.viewAttributionWindows),
        currency: this.getCurrencyObj(this.lens.currency),
        timezone: this.getTimezoneObj(this.lens.timezone)
      }

      // Initialize benchmark settings from lens data
      this.benchmarkSettings = {
        selectedIndustry: this.getIndustryObj(this.lens.niche),
        selectedAOV: this.getAOVObj(this.lens.aov_min, this.lens.aov_max),
        selectedRevenue: this.getRevenueObj(this.lens.gmv)
      }
    },

    getAttributionWindowObj (value, options) {
      if (!value) return null
      return options.find(option => option.value === value) || null
    },

    getCurrencyObj (currencyCode) {
      if (!currencyCode) return null
      return this.currencies.find(currency => currency.value === currencyCode) || null
    },

    getTimezoneObj (timezoneValue) {
      if (!timezoneValue) return null
      return this.timeZones.find(tz => tz.value === timezoneValue) || null
    },

    getIndustryObj (nicheValue) {
      if (!nicheValue) return null
      return this.industryOptions.find(industry => industry.value === nicheValue) || null
    },

    getAOVObj (minValue, maxValue) {
      // Map the aov_min and aov_max to the appropriate AOV option
      if (minValue === null && maxValue === null) return null

      // This is a simple implementation - may need to be adjusted based on your actual data format
      if (minValue === 0 && maxValue === 100) {
        return this.aovOptions.find(option => option.value === '<$100') || null
      } else {
        return this.aovOptions.find(option => option.value === '$100+') || null
      }
    },

    getRevenueObj (gmvValue) {
      if (!gmvValue) return null

      // This is a simple implementation - may need to be adjusted based on your actual data format
      if (gmvValue < 1000000) {
        return this.revenueOptions.find(option => option.value === '<$1M') || null
      } else if (gmvValue < 10000000) {
        return this.revenueOptions.find(option => option.value === '$1M-$10M') || null
      } else {
        return this.revenueOptions.find(option => option.value === '$10M+') || null
      }
    },

    initializeDropdownData () {
      // Transform timezone data
      this.timeZones = Object.entries(timeZonesData).map(([zone, data]) => ({
        label: `${zone}`,
        value: data.IANA
      }))

      // Transform currency data
      this.currencies = Object.entries(currenciesData)
        .filter(([_, data]) => data.ISOnum !== null) // Filter out non-ISO currencies
        .map(([code, data]) => ({
          label: `${data.name} (${code})`,
          value: code
        }))
    },
    handleFileInput () {
      this.$refs.fileInput.click()
    },
    handleFileUpload (event) {
      const mediaFile = event.target.files[0]
      if (!mediaFile) return
      this.avatarUpload = mediaFile
      const fileType = mediaFile.type
      if (!(fileType === 'image/png' || fileType === 'image/jpeg')) {
        this.$showAlert({
          message: 'Only JPEG and PNG accepted',
          type: 'error'
        })
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.avatarPreview = e.target.result
        this.checkForChanges()
      }
      reader.readAsDataURL(mediaFile)
    },
    removeAvatar () {
      if (!this.hasAvatarToRemove) return
      this.avatarPreview = ''
      this.avatarUpload = null
      this.avatarWasRemoved = true
      this.checkForChanges()
    },
    updateBenchmarkField (field, value) {
      console.log(`updateBenchmarkField called with field: ${field}, value:`, value)

      // Create a new object with the updated field
      this.benchmarkSettings = {
        ...this.benchmarkSettings,
        [field]: value
      }

      console.log('Updated benchmarkSettings:', this.benchmarkSettings)
      this.checkForChanges()
    },
    async handleFormSubmit () {
      console.log('handleFormSubmit called in LensSettings')
      this.submitAttempted = true
      this.showErrors = true

      // Log the current state of benchmark and ad account settings
      console.log('Current benchmarkSettings:', JSON.stringify(this.benchmarkSettings))
      console.log('Current adAccountSettings:', JSON.stringify(this.adAccountSettings))

      // Check if the company name is filled in
      if (!this.form.companyName && !this.lens.name) {
        this.$showAlert({
          message: 'Please fill in all required fields',
          type: 'error'
        })
        return
      }

      this.loadingSubmit = true
      try {
        // Create an empty update payload and only add fields that have changed
        const lensUpdatePayload = {}

        // Only add name if it changed or if form.companyName is filled
        if (this.form.companyName && this.form.companyName !== this.lens.name) {
          lensUpdatePayload.name = this.form.companyName
          lensUpdatePayload.company_name = this.form.companyName
        }

        // Handle avatar changes
        if (this.avatarUpload) {
          // Upload new avatar
          const folderName = nanoid()
          const fileName = nanoid()
          const filePath = `${folderName}/${fileName}`
          const storageRef = firebase.app().storage('gs://foreplay-user-avatars').ref(filePath)

          await storageRef.put(this.avatarUpload)
          lensUpdatePayload.avatar = `https://storage.googleapis.com/foreplay-user-avatars/${filePath}`

          // Reset the avatarUpload after successful upload
          this.avatarUpload = null
          this.avatarPreview = ''
        } else if (this.avatarPreview === '' && this.lens.avatar && this.avatarWasRemoved) {
          // Only set to null if we explicitly removed the avatar
          lensUpdatePayload.avatar = null
        }

        // Add benchmark settings to the payload if they've changed
        if (this.benchmarkSettings.selectedIndustry &&
            this.benchmarkSettings.selectedIndustry.value !== this.lens.niche) {
          lensUpdatePayload.niche = this.benchmarkSettings.selectedIndustry.value
        }

        // Handle AOV settings
        if (this.benchmarkSettings.selectedAOV) {
          const newAovMin = this.getAOVMinValue(this.benchmarkSettings.selectedAOV)
          const newAovMax = this.getAOVMaxValue(this.benchmarkSettings.selectedAOV)

          // Only add to payload if values have changed
          if (newAovMin !== this.lens.aov_min) {
            lensUpdatePayload.aov_min = newAovMin
          }

          if (newAovMax !== this.lens.aov_max) {
            lensUpdatePayload.aov_max = newAovMax
          }
        }

        // Handle Revenue/GMV settings
        if (this.benchmarkSettings.selectedRevenue) {
          const newGmv = this.getRevenueValue(this.benchmarkSettings.selectedRevenue)
          if (newGmv !== this.lens.gmv) {
            lensUpdatePayload.gmv = newGmv
          }
        }

        // Add ad account settings to the payload if they've changed
        if (this.adAccountSettings.goalMetric &&
            this.adAccountSettings.goalMetric !== this.lens.goal_metric) {
          lensUpdatePayload.goal_metric = this.adAccountSettings.goalMetric
        }

        // Handle attribution window settings
        if (this.adAccountSettings.attributionWindowClickType &&
            this.adAccountSettings.attributionWindowClickType.value !== this.lens.click_attribution_window) {
          lensUpdatePayload.click_attribution_window = this.adAccountSettings.attributionWindowClickType.value
        }

        if (this.adAccountSettings.attributionWindowViewType &&
            this.adAccountSettings.attributionWindowViewType.value !== this.lens.view_attribution_window) {
          lensUpdatePayload.view_attribution_window = this.adAccountSettings.attributionWindowViewType.value
        }

        // Handle timezone and currency settings
        if (this.adAccountSettings.timezone &&
            this.adAccountSettings.timezone.value !== this.lens.timezone) {
          lensUpdatePayload.timezone = this.adAccountSettings.timezone.value
        }

        if (this.adAccountSettings.currency &&
            this.adAccountSettings.currency.value !== this.lens.currency) {
          lensUpdatePayload.currency = this.adAccountSettings.currency.value
        }

        console.log('Updating lens with payload:', lensUpdatePayload)

        // Only update if there are changes
        if (Object.keys(lensUpdatePayload).length > 0) {
          // Update the lens using the API
          const response = await LensAPI.Lens.update(this.lens.id, lensUpdatePayload)
          console.log('Update response:', response)

          this.$showAlert({
            message: 'Lens settings updated successfully',
            type: 'success'
          })

          // Refresh lens data
          await this.$store.dispatch('LensModule/fetchLenses')

          // Reset the changes flag
          this.hasUnsavedChanges = false
          this.$root.$emit('lens-settings-has-changes', false)
        } else {
          this.$showAlert({
            message: 'No changes to save',
            type: 'info'
          })
        }
      } catch (error) {
        console.error('Error updating lens settings:', error)
        this.$showAlert({
          message: 'Failed to update lens settings. Please try again.',
          type: 'error'
        })
      } finally {
        this.loadingSubmit = false
        this.showErrors = false
      }
    },
    // Helper methods for converting dropdown selections to appropriate values
    getAOVMinValue (selectedAOV) {
      if (!selectedAOV) return this.lens.aov_min
      return selectedAOV.value === '<$100' ? 0 : 100
    },

    getAOVMaxValue (selectedAOV) {
      if (!selectedAOV) return this.lens.aov_max
      return selectedAOV.value === '<$100' ? 100 : null
    },

    getRevenueValue (selectedRevenue) {
      if (!selectedRevenue) return this.lens.gmv

      switch (selectedRevenue.value) {
        case '<$1M':
          return 999999
        case '$1M-$10M':
          return 5000000
        case '$10M+':
          return 10000000
        default:
          return null
      }
    },
    async resetPassword () {
      this.loadingPasswordReset = true
      const user = firebase.auth().currentUser
      try {
        await firebase.auth().sendPasswordResetEmail(user.email)
        this.passwordResetEmailSent = true
      } catch (error) {
        this.$showAlert({
          message: error,
          type: 'error'
        })
      } finally {
        this.loadingPasswordReset = false
      }
    },
    async deleteOldAvatar (filePath) {
      const storageRef = firebase.app().storage('gs://foreplay-user-avatars').ref()
      const oldAvatarRef = storageRef.child(filePath.split('https://storage.googleapis.com/foreplay-user-avatars/')[1])
      await oldAvatarRef.delete()
    },
    updateAdAccountField (field, value) {
      console.log(`updateAdAccountField called with field: ${field}, value:`, value)

      // Create a new object with the updated field
      this.adAccountSettings = {
        ...this.adAccountSettings,
        [field]: value
      }

      console.log('Updated adAccountSettings:', this.adAccountSettings)
      this.checkForChanges()
    },
    handleSelectGoalMetric (key) {
      console.log('Goal metric selected:', key)
      // Update the goal metric in adAccountSettings
      this.adAccountSettings = {
        ...this.adAccountSettings,
        goalMetric: key
      }
      console.log('Updated adAccountSettings:', this.adAccountSettings)
      this.showGoalMetricDropdown = false
      this.checkForChanges()
    },
    updateFormField (field, value) {
      this.form = {
        ...this.form,
        [field]: value
      }
      this.checkForChanges()
    },
    async handleConnectMeta () {
      try {
        this.loading = true

        // Check if a connection exists first
        const accountExists = await LensAPI.Lens.getTWAccounts()

        // If we have accounts, use redirect flow regardless of is_valid status
        if (accountExists.data && accountExists.data.length > 0) {
          // Use the internal_tw_account_id from the response for redirect
          const twAccountId = accountExists.data[0]?.internal_tw_account_id
          const { url } = await LensAPI.Lens.getTWRedirect(twAccountId)
          this.url = url
          this.twAccountId = twAccountId
        } else {
          // Create an account on TW only if no accounts exist
          const { internal_tw_account_id: twAccountId } = await LensAPI.Lens.createTWAccount(this.lens.currency, this.lens.timezone)
          const { url } = await LensAPI.Lens.getTWRedirect(twAccountId)
          this.url = url
          this.twAccountId = twAccountId
        }

        // Open authentication window in a new tab
        this.openAuthWindow()
      } catch (error) {
        console.error('Failed to get Meta connection URL:', error)
        this.$showAlert({
          message: 'Failed to connect to Meta',
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    openAuthWindow () {
      // Calculate center position for the window
      const width = 600
      const height = 600
      const left = (window.screen.width - width) / 2
      const top = (window.screen.height - height) / 2

      // Open new window centered on screen
      try {
        this.authWindow = window.open(
          this.url,
          'MetaAuth',
          `width=${width},height=${height},left=${left},top=${top}`
        )
      } catch (error) {
        console.error('Error opening auth window:', error)
      }
    },
    getInitials (name) {
      if (!name) return ''
      return name.split(' ').map(word => word[0]).join('').toUpperCase().substring(0, 2)
    },
    checkForChanges () {
      // Check if any settings have changed from their original values
      let hasChanges = false

      // Check company name
      if (this.form.companyName && this.form.companyName !== this.lens.name) {
        hasChanges = true
      }

      // Check avatar changes
      if (this.avatarUpload || (this.avatarWasRemoved && this.lens.avatar)) {
        hasChanges = true
      }

      // Check benchmark settings
      if (this.benchmarkSettings.selectedIndustry &&
          this.benchmarkSettings.selectedIndustry.value !== this.lens.niche) {
        hasChanges = true
      }

      // Check AOV settings
      if (this.benchmarkSettings.selectedAOV) {
        const newAovMin = this.getAOVMinValue(this.benchmarkSettings.selectedAOV)
        const newAovMax = this.getAOVMaxValue(this.benchmarkSettings.selectedAOV)
        if (newAovMin !== this.lens.aov_min || newAovMax !== this.lens.aov_max) {
          hasChanges = true
        }
      }

      // Check Revenue/GMV settings
      if (this.benchmarkSettings.selectedRevenue) {
        const newGmv = this.getRevenueValue(this.benchmarkSettings.selectedRevenue)
        if (newGmv !== this.lens.gmv) {
          hasChanges = true
        }
      }

      // Check ad account settings
      if (this.adAccountSettings.goalMetric &&
          this.adAccountSettings.goalMetric !== this.lens.goal_metric) {
        hasChanges = true
      }

      // Check attribution window settings
      if (this.adAccountSettings.attributionWindowClickType &&
          this.adAccountSettings.attributionWindowClickType.value !== this.lens.click_attribution_window) {
        hasChanges = true
      }

      if (this.adAccountSettings.attributionWindowViewType &&
          this.adAccountSettings.attributionWindowViewType.value !== this.lens.view_attribution_window) {
        hasChanges = true
      }

      // Check timezone and currency settings
      if (this.adAccountSettings.timezone &&
          this.adAccountSettings.timezone.value !== this.lens.timezone) {
        hasChanges = true
      }

      if (this.adAccountSettings.currency &&
          this.adAccountSettings.currency.value !== this.lens.currency) {
        hasChanges = true
      }

      // Update the hasUnsavedChanges flag
      this.hasUnsavedChanges = hasChanges

      // Emit an event to notify parent components about the change status
      this.$root.$emit('lens-settings-has-changes', hasChanges)
    },
    openConfirmDeleteLensModal () {
      this.showConfirmDeleteLensModal = true
    },
    async handleDeleteLens () {
      try {
        this.loading = true
        this.showConfirmDeleteLensModal = false

        // Call the API to delete the lens
        await LensAPI.Lens.deleteLens(this.lens.id)

        // Show success message
        this.$showAlert({
          message: 'Lens deleted successfully',
          type: 'success'
        })

        // Refresh lenses in the store
        await this.$store.dispatch('LensModule/fetchLenses')

        // Navigate to the lenses list page
        this.$router.push({ name: 'lenses' }).catch(err => {
          console.error('Error navigating to lenses list:', err)
        })
      } catch (error) {
        console.error('Error deleting lens:', error)
        this.$showAlert({
          message: 'Failed to delete lens. Please try again.',
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.section-grid {
  display: grid;
  grid-template-columns: 440px 440px;
}
@media (max-width: 1280px) {
  .section-grid {
    display: block;
    width: 100%;
    max-width: 640px;
  }
}

.standard-btn {
  background: linear-gradient(180deg, rgba(223, 225, 231, 0.08) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out;
}
.standard-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input.invalid {
  box-shadow: none;
  border-color: #FF002F;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}
.reset-password-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}
.reset-password-btn.enabled:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.reset-password-btn.disabled {
  cursor: default;
  background-color: #F8FAFB;
  box-shadow: none;
}

.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0.25 }
  to { opacity: 1 }
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-color {
  syntax: '<color>';
  initial-value: #0063F4;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out,
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out,
    --submit-btn-opacity-2 100ms ease-in-out,
    --submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --submit-color: #F6F8FA;
}

.goal-metric-button {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out;
  border-radius: 8px;
}
.goal-metric-button:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.goal-metric-dropdown {
  position: absolute;
  top: -4px;
  left: -2px;
  right: -2px;
  z-index: 500;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
